<template>
  <div  v-if="comanda && comanda.productos && comanda.productos.length > 0">
    <v-row v-if="comanda" class="my-4 ml-2 mr-1">
      <v-col cols="8" class="my-0 py-0 px-0">
        <v-card  class="contenedor-productos">
          <v-card-text>
            <v-row>
              <v-col>
                <h3>
                  <v-icon>{{ icons.mdiAccountTie }} </v-icon> {{ comanda.mesonero_nombre }}
                  {{ comanda.mesonero_apellido }}
                </h3>
              </v-col>
              <v-col>
                <h3 class="text-center">
                  <v-icon>{{ icons.mdiTableChair }} </v-icon> {{ comanda.grupo_mesa }} {{ comanda.mesa_nombre }}
                </h3>
              </v-col>
              <v-col>
                <h3 class="text-right primary--text">
                  <v-icon>{{ icons.mdiInvoiceText }} </v-icon> {{ comanda.id <= 0 ? 'Nueva' : comanda.id }}
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-simple-table height="calc(75vh - 130px)" ref="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Producto</th>
                  <th class="text-center">Cantidad</th>
                  <th class="text-right">Precio</th>
                  <th class="text-right">SubTotal</th>
                  <th class="text-right">Descuento</th>
                  <th class="text-right">Iva</th>
                  <th class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in comanda.productos" :key="index">
                  <td>
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <span
                                v-if="item.producto.nombre.length < 30"
                                class="d-block font-weight-semibold text--primary text-truncate"
                              >
                                {{ FuncionesGenerales.camelCase(item.producto.nombre) }}
                              </span>

                              <span v-else class="d-block font-weight-semibold text--primary text-truncate">
                                {{ FuncionesGenerales.camelCase(item.producto.nombre.substring(0, 27)) }}...
                              </span>
                            </span>
                          </template>
                          <span> {{ FuncionesGenerales.camelCase(item.producto.nombre) }}</span>
                        </v-tooltip>

                        <small> Barra: {{ FuncionesGenerales.camelCase(item.producto.referencia) }} </small>
                        <small v-if="item.observacion">
                          {{ FuncionesGenerales.camelCase(item.observacion.substring(0, 30)) }}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td class="text-right">
                    {{ item.cantidad }}
                  </td>
                  <td class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate">
                      ${{ item.precio_real.toFixed(2) }}
                    </span>
                    <small class="primary--text"> {{ `${MonedaPais.simbolo} ${item.precio_real.toFixed(2)} ` }} </small>
                  </td>
                  <td class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate">
                      {{ `$.${round(Number(item.precio_real) * item.cantidad).toFixed(2)}` }}
                    </span>
                    <small class="primary--text">
                      {{
                        `${MonedaPais.simbolo}.${round(
                          round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad,
                        ).toFixed(2)}`
                      }}
                    </small>
                  </td>
                  <td class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate"
                      >{{
                        `$.${round((item.por_descuento / 100) * (Number(item.precio_real) * item.cantidad)).toFixed(2)}`
                      }}
                      <small class="error--text" v-if="item.por_descuento > 0">{{ item.por_descuento }}%</small>
                    </span>
                    <small class="primary--text">{{
                      `${MonedaPais.simbolo}.${(
                        round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                        (item.por_descuento / 100)
                      ).toFixed(2)}`
                    }}</small>
                  </td>
                  <td class="text-right">
                    <div class="d-flex flex-column">
                      <span class="d-block font-weight-semibold text--primary text-truncate">{{
                        `$.${round(
                          (round(round(item.precio_real) * item.cantidad).toFixed(2) -
                            round(round(round(item.precio_real) * item.cantidad) * (item.por_descuento / 100))) *
                            (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
                        ).toFixed(2)}`
                      }}</span>
                      <small class="primary--text">{{
                        `${MonedaPais.simbolo}.${round(
                          (round(round(item.precio_real * MonedaPais.tasa) * item.cantidad).toFixed(2) -
                            round(
                              round(round(item.precio_real * MonedaPais.tasa) * item.cantidad) *
                                (item.por_descuento / 100),
                            )) *
                            (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
                        ).toFixed(2)}`
                      }}</small>
                    </div>
                  </td>
                  <td class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{
                      `$.${round(
                        (Number(item.precio_real) * item.cantidad -
                          round(round(round(Number(item.precio_real)) * item.cantidad) * (item.por_descuento / 100))) *
                          (1 + (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0)),
                      ).toFixed(2)}`
                    }}</span>
                    <small class="primary--text">{{
                      `${MonedaPais.simbolo}.${round(
                        round(
                          Number(item.precio_real) * MonedaPais.tasa * item.cantidad -
                            round(
                              round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                                (item.por_descuento / 100),
                            ),
                        ) +
                          round(
                            (Number(item.precio_real) * MonedaPais.tasa * item.cantidad -
                              round(
                                round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                                  (item.por_descuento / 100),
                              )) *
                              (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
                          ),
                      ).toFixed(2)}`
                    }}</small>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card class="contenedor-totales mt-2">
          <v-row>
            <v-col cols="6">
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="text-left"><strong>SUBTOTAL: </strong></td>
                      <td class="text-right">
                        <span>$.{{ round(comanda.subtotal).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>{{ MonedaPais.simbolo }}.{{ round(comanda.subtotalMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="text-left">
                        <strong>DESC:({{ comanda.por_descuento }}%)</strong>
                      </td>
                      <td class="text-right">
                        <span>-$.{{ round(comanda.descuento).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>-{{ MonedaPais.simbolo }}.{{ round(comanda.descuentoMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <strong>SERV({{ comanda.por_comision }}%):</strong>
                      </td>
                      <td class="text-right">
                        <span>+$ {{ comanda.servicio.toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>+{{ MonedaPais.simbolo }}.{{ round(comanda.servicioMonedaPais.toFixed(2)) }} </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>IVA:</strong></td>
                      <td class="text-right">
                        <span>+$.{{ round(comanda.iva).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>+{{ MonedaPais.simbolo }}.{{ round(comanda.ivaMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>IGTF:</strong></td>
                      <td class="text-right">
                        <span>+$.{{ round(comanda.igtf).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>+{{ MonedaPais.simbolo }}.{{ comanda.igtfMonedaPais.toFixed(2) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="text-left"><strong>TOTAL:</strong></td>
                      <td class="text-right">$.{{ round(comanda.total).toFixed(2) }}</td>
                      <td class="text-right">
                        {{ MonedaPais.simbolo }}.{{ round(comanda.totalMonedaPais).toFixed(2) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>PROPINA:</strong></td>

                      <td class="text-right">
                        <span class=" ">$.{{ round(totalesPropina().totalMoneda).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span class=" ">Bs.{{ round(totalesPropina().totalMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>Tasa Bs:</strong></td>

                      <td class="text-right" colspan="2" >
                        <span class=" ">
                         {{MonedaPais.simbolo}}.{{ round(MonedaPais.tasa).toFixed(2) }}</span>
                      </td>
                      
                    </tr>
                   

                 
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
     
      <v-col cols="4">
        <v-row>
          <v-col cols="12" class="mt-0 pt-0" style="height: 20vh">
            <v-card style="height: 20vh">
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-img :src="require('@/assets/images/3d-characters/14.png')"></v-img>
                  </v-col>
                  <v-col>
                    <div v-if="comanda.id_cliente > 0">
                      <p class="py-0 my-0">#:{{ comanda.id_cliente }}</p>

                      <h3 class=" ">{{ comanda.nombre_cliente }} {{ comanda.apellido_cliente }}</h3>

                      <p class="py-0 my-0">{{ comanda.documento }}</p>
                      <p class="py-0 my-0">direccion</p>
                    </div>
                    <div v-else>
                      <h2>Cliente</h2>
                      <p class="py-0 my-0">Debe seleccionar un cliente</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pt-2" cols="12" style="height: calc(80vh - 157px)">
            <v-card style="height: calc(80vh - 157px)">
              <v-card-text>
                <v-row>
                  <v-list width="100%">
                    <div class="text-center mt-4">
                      <small>Pagos</small>
                    </div>

                    <v-divider></v-divider>
                    <template v-for="(item, index) in comanda.pagos">
                      <v-list-item :key="`PAGO${index}`">
                        <v-list-item-avatar size="30">
                          <v-avatar
                            :color="item.punto_venta ? '' : 'primary'"
                            :class="item.punto_venta ? '' : 'v-avatar-light-bg white--text'"
                            size="32"
                          >
                            <v-img
                              v-if="item.punto_venta"
                              :src="config.api_imagenes + '/banco/img/s-' + item.punto_venta.banco.img_icon"
                            >
                            </v-img>
                            <span v-else> {{ item.moneda.simbolo }}</span>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content class="p-0 m-0">
                          <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }}</v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0"
                            >{{ item.moneda.tipo_pago.filter(it => it.id == item.tipo_pago)[0].nombre }}
                            {{ item.moneda.simbolo }}: {{ Number(item.monto_moneda).toFixed(2) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1"
                            ><small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="MonedaPais.simbolo != item.moneda.simbolo"
                            ><small
                              >Monto {{ MonedaPais.simbolo }}:
                              {{ (item.monto_real * MonedaPais.tasa).toFixed(2) }}</small
                            >
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0" v-if="item.punto_venta">
                            <small>{{ item.punto_venta.banco.nombre }} | {{ item.punto_venta.codigo }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                            ><small> Ref: {{ item.referencia }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="py-0 my-0 success--text"
                            v-if="item.vuelto_info.monto_moneda > 0"
                          >
                            <small
                              >Vuelto
                              {{
                                item.vuelto_info.moneda.tipo_pago.filter(it => it.id == item.vuelto_info.tipo_pago)[0]
                                  .nombre
                              }}
                            </small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="py-0 my-0 success--text"
                            v-if="item.vuelto_info.monto_moneda > 0"
                          >
                            <small>
                              {{ item.vuelto_info.moneda.simbolo }}
                              {{ item.vuelto_info.monto_moneda.toFixed(2) }}</small
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="`PAGO${index}`"></v-divider>
                    </template>
                    <v-divider></v-divider>
                    <div class="text-center" v-if="comanda.propinas && comanda.propinas.length > 0">
                      <small> Propinas</small>
                    </div>
                    <v-divider></v-divider>
                    <template v-for="(item, index) in comanda.propinas">
                      <v-list-item :key="`propina${index}`" class="grey4">
                        <v-list-item-avatar size="30">
                          <v-avatar
                            :color="item.punto_venta ? '' : 'primary'"
                            :class="item.punto_venta ? '' : 'v-avatar-light-bg primary--text'"
                            size="32"
                          >
                            <v-img
                              v-if="item.punto_venta"
                              :src="config.api_imagenes + '/banco/img/s-' + item.punto_venta.banco.img_icon"
                            >
                            </v-img>
                            <span v-else class="white--text"> {{ item.moneda.simbolo }}</span>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content class="p-0 m-0">
                          <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }}</v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0">
                            <small v-if="item.id_pago_propina_vuelto"> PROPINA VUELTO <br /></small>
                            {{ item.moneda.tipo_pago.filter(it => it.id == item.tipo_pago)[0].nombre }}
                            {{ item.moneda.simbolo }}: {{ Number(item.monto_moneda).toFixed(2) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1"
                            ><small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="MonedaPais.simbolo != item.moneda.simbolo"
                            ><small
                              >Monto {{ MonedaPais.simbolo }}:
                              {{ (item.monto_real * MonedaPais.tasa).toFixed(2) }}</small
                            >
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.punto_venta">
                            <small>{{ item.punto_venta.banco.nombre }} | {{ item.punto_venta.codigo }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                            ><small> Ref: {{ item.referencia }}</small>
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0">
                            <strong>
                              <small> {{ item.empleado_propina }}</small></strong
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="`propina${index}`"></v-divider>
                    </template>
                  </v-list>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col>
                <v-card class="primary text-center white--text" style="height: 100px">
                  <p class="white--text">TOTAL</p>
                  <h3 class="text-center">
                    <span class="white--text"
                      >$.{{ round(totalesPropina().totalMoneda + comanda.total).toFixed(2) }}</span
                    >
                  </h3>
                  <h4 class="text-center">
                    <span class="white--text"
                      >{{ MonedaPais.simbolo }}.{{
                        round(totalesPropina().totalMonedaPais + comanda.totalMonedaPais).toFixed(2)
                      }}</span
                    >
                  </h4>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="text-center" :class="comanda.resta > 0 ? 'error' : 'success'" style="height: 100px">
                  <p class="white--text">{{ comanda.resta > 0 ? 'RESTA' : 'VUELTO' }}</p>
                  <h3 class="text-center">
                    <span class="white--text">$.{{ round(comanda.resta * -1).toFixed(2) }}</span>
                  </h3>
                  <h4 class="text-center">
                    <span class="white--text"
                      >{{ MonedaPais.simbolo }}.{{ round(comanda.restaMonedaPais * -1).toFixed(2) }}</span
                    >
                  </h4>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row class="mt-1">
      <v-col cols="9" class="px-0 mx-0 py-0 my-0">
        
          <v-card-text>
            <v-carousel height="94vh"  hide-delimiter-background show-arrows-on-hover cycle>
              <v-carousel-item   v-for="(item, i) in publicidad" :key="i" :src="item.src"></v-carousel-item>
            </v-carousel>
          </v-card-text>
       
      </v-col>
      <v-col cols="3">
        <v-card style="height: 96vh">
          <v-card-text class="text-center justify-center align-center" style="height: 77vh">
            <h2 class="text-center">Bienvenido</h2>
            <br />
            <v-img contain height="100px" :src="store.state.configuracionGeneral.logo_empresa_2"></v-img>
          </v-card-text>

          <v-card-text class="text-center">
            <!-- divider -->
            <v-card-text class="d-flex align-center mt-2">
              <v-divider></v-divider>
              <span class="mx-5">by</span>
              <v-divider></v-divider>
            </v-card-text>

            <!-- social links -->
            <v-card-actions class="d-flex justify-center">
              <v-img
                :src="require(`@/assets/images/logos/logoarty/ArtyTechnologyGris.png`)"
                height="50px"
                alt="logo"
                contain
                class="my-0"
              ></v-img>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, onBeforeMount } from '@vue/composition-api'
import store from '@/store'
import FuncionesGenerales from '@/funciones/funciones'
import {
  mdiPoll,
  mdiLabelVariantOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiShopping,
  mdiCashRegister,
  mdiCached,
  mdiDelete,
  mdiBarcode,
  mdiFood,
  mdiDotsHorizontal,
  mdiPercentOutline,
  mdiSale,
  mdiCancel,
  mdiKeyboardF12,
  mdiKeyboardF1,
  mdiKeyboardF2,
  mdiKeyboardF3,
  mdiKeyboardF4,
  mdiKeyboardF5,
  mdiKeyboardF6,
  mdiKeyboardF7,
  mdiKeyboardF8,
  mdiKeyboardF9,
  mdiKeyboardF10,
  mdiKeyboardF11,
  mdiKeyboardEsc,
  mdiKeyboardCaps,
  mdiFileEdit,
  mdiAccountTie,
  mdiTableChair,
} from '@mdi/js'
export default {

  setup() {
    const comanda = ref(null)
    const publicidad = ref([
      {
        src: "https://c8.alamy.com/compes/2gbtxt6/oferta-especial-de-diseno-de-plantilla-de-banner-de-moda-banner-de-promocion-para-oferta-de-temporada-promocion-publicidad-2gbtxt6.jpg",
      },
      {
        src:  "https://img.freepik.com/vector-premium/promocion-banner-super-venta_131000-345.jpg",
      }, 
      {
        src:  "https://trazoweb.co/wp-content/uploads/2022/12/BN010.jpg",
      },
    ])
    const monedas = ref([
      {
        id: 1,
        nombre: 'DOLAR',
        descripcion: 'MONEDA ESTADOUNIDENSE',
        simbolo: '$',
        tasa: 1,
        actual: true,
        estado: 5,
        meneda_pais: false,
        id_tipo_uso: 1,
        tipo_pago: [
          {
            id: 1,
            nombre: 'EFECTIVO',
            descripcion: 'PAGO EN EFECTIVO ',
            moneda: 1,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: true,
            id_tipo_caja_banco: 2,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: 1,
            fecha_crea: null,
            fecha_modifica: '2024-05-20T11:43:43.822706',
            ind_propina: true,
            ind_pago_compras: true,
            ind_cuenta_bancaria: true,
          },
          {
            id: 2,
            nombre: 'ZELLE',
            descripcion: 'PAGO EN TRANSFERENCIA',
            moneda: 1,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: true,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: 1,
            fecha_crea: null,
            fecha_modifica: '2024-03-06T11:55:25.287719',
            ind_propina: false,
            ind_pago_compras: true,
            ind_cuenta_bancaria: true,
          },
          {
            id: 12,
            nombre: 'TICKET',
            descripcion: 'PAGO TICKET EVENTOS',
            moneda: 1,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: null,
            fecha_crea: null,
            fecha_modifica: null,
            ind_propina: false,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
          {
            id: 14,
            nombre: 'TJ INTER',
            descripcion: 'PAGO EN CREDITO DOLAR',
            moneda: 1,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: true,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: null,
            fecha_crea: null,
            fecha_modifica: null,
            ind_propina: false,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
        ],
      },
      {
        id: 2,
        nombre: 'BOLIVAR',
        descripcion: 'MODEDA VENEZOLANA',
        simbolo: 'BS',
        tasa: 36.5,
        actual: false,
        estado: 5,
        meneda_pais: true,
        id_tipo_uso: 1,
        tipo_pago: [
          {
            id: 3,
            nombre: 'EFECTIVO',
            descripcion: 'PAGO EN EFECTIVO',
            moneda: 2,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 2,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: 1,
            fecha_crea: null,
            fecha_modifica: '2024-05-20T11:44:10.307277',
            ind_propina: true,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
          {
            id: 4,
            nombre: 'DEBITO',
            descripcion: 'PAGO EN DEBITO',
            moneda: 2,
            estado: 5,
            id_fiscal: '03',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: true,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: 1,
            fecha_crea: null,
            fecha_modifica: '2024-03-01T11:12:08.590802',
            ind_propina: true,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
          {
            id: 5,
            nombre: 'CREDITO',
            descripcion: 'PAGO EN TARJETA DE CREDITO',
            moneda: 2,
            estado: 5,
            id_fiscal: '04',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: 1,
            fecha_crea: null,
            fecha_modifica: '2024-02-27T12:51:10.743757',
            ind_propina: true,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
          {
            id: 6,
            nombre: 'PAGO MOVIL',
            descripcion: 'PAGO PLATAFORM ADE PAGO MOVIL',
            moneda: 2,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: null,
            fecha_crea: null,
            fecha_modifica: null,
            ind_propina: false,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
          {
            id: 17,
            nombre: 'VUELTO PAGO MOVIL',
            descripcion: 'VUELTO PAGO MOVIL',
            moneda: 2,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: true,
            ind_pago: false,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: null,
            fecha_crea: null,
            fecha_modifica: null,
            ind_propina: false,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
          {
            id: 18,
            nombre: 'TRANSFERENCIA',
            descripcion: 'TRANSFERENCIA',
            moneda: 2,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: 1,
            fecha_crea: null,
            fecha_modifica: '2024-03-01T12:02:07.30851',
            ind_propina: false,
            ind_pago_compras: true,
            ind_cuenta_bancaria: true,
          },
        ],
      },
      {
        id: 3,
        nombre: 'EURO',
        descripcion: 'MONEDA EUROPEA',
        simbolo: '€',
        tasa: 1,
        actual: false,
        estado: 5,
        meneda_pais: false,
        id_tipo_uso: 1,
        tipo_pago: [
          {
            id: 9,
            nombre: 'EFECTIVO',
            descripcion: 'PAGO EN EFECTIVO',
            moneda: 3,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: true,
            id_tipo_caja_banco: 2,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: 1,
            fecha_crea: null,
            fecha_modifica: '2024-05-20T11:43:55.296841',
            ind_propina: false,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
        ],
      },
      {
        id: 4,
        nombre: 'OTROS',
        descripcion: 'OTROS',
        simbolo: 'Ot',
        tasa: 1,
        actual: false,
        estado: 5,
        meneda_pais: false,
        id_tipo_uso: 1,
        tipo_pago: [
          {
            id: 10,
            nombre: 'POR COBRAR',
            descripcion: 'CUENTAS POR COBRAR',
            moneda: 4,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: null,
            fecha_crea: null,
            fecha_modifica: null,
            ind_propina: false,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
          {
            id: 11,
            nombre: 'CORTESIA',
            descripcion: 'CORTESIA',
            moneda: 4,
            estado: 5,
            id_fiscal: '01',
            ind_vuelto: false,
            ind_pago: true,
            ind_igtf: false,
            id_tipo_caja_banco: 1,
            ind_punto_venta: false,
            ind_descripcion: false,
            ind_referencia: false,
            id_empleado_crea: null,
            id_empleado_modifica: null,
            fecha_crea: null,
            fecha_modifica: null,
            ind_propina: false,
            ind_pago_compras: false,
            ind_cuenta_bancaria: false,
          },
        ],
      },
    ])

    const MonedaPais = ref(monedas.value.find(item => item.meneda_pais == true))

    const cargarLocalstore = () => {
      const datos = JSON.parse(localStorage.getItem('vuex'))

      comanda.value = datos.comandaActual
    }

    onBeforeMount(() => {
      setInterval(() => {
        cargarLocalstore()
      }, 1000)
    })
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    const totalesPropina = () => {
      let sumas = {
        totalMonedaPais: 0,
        totalMoneda: 0,
      }

      comanda.value.propinas.forEach(element => {
        sumas.totalMonedaPais += Number(element.monto_real) * MonedaPais.value.tasa
        sumas.totalMoneda += Number(element.monto_real)
      })

      return sumas
    }

    return {
      totalesPropina,
      comanda,
      MonedaPais,
      round,
      FuncionesGenerales,
      store,
      publicidad,
      icons: {
        mdiCached,
        mdiDelete,
        mdiBarcode,
        mdiFood,
        mdiDotsHorizontal,
        mdiPercentOutline,
        mdiSale,
        mdiCancel,
        mdiKeyboardF12,
        mdiKeyboardF1,
        mdiKeyboardF2,
        mdiKeyboardF3,
        mdiKeyboardF4,
        mdiKeyboardF5,
        mdiKeyboardF7,
        mdiKeyboardF8,
        mdiKeyboardF9,
        mdiKeyboardF10,
        mdiKeyboardF11,
        mdiKeyboardF6,
        mdiKeyboardEsc,
        mdiKeyboardCaps,
        mdiFileEdit,
        mdiAccountTie,
        mdiTableChair,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
<style scoped>
.icon-tecla {
  position: absolute;
  top: 0;
  right: 0;
}
.contenedor-productos {
  height: calc(75vh - 50px);
  overflow-x: hidden;
  overflow-y: auto;
}
.contenedor-totales {
  height: calc(25vh);
  overflow-x: hidden;
  overflow-y: auto;
}

.inputPrice >>> input[type='number'] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.centered-input >>> input {
  text-align: center;
}
</style>
